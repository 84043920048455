/**
 * Copyright 2023 LiveKit, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  LiveKitRoom,
  RoomAudioRenderer,
  TrackReference,
  useRoomContext,
  useTracks,
} from '@livekit/components-react';
import EgressHelper from '@livekit/egress-sdk';
import { ConnectionState, RoomEvent, Track } from 'livekit-client';
import { ReactElement, useEffect, useState } from 'react';
import SingleSpeakerLayout from './SingleSpeakerLayout';
import SpeakerLayout from './SpeakerLayout';
import { ParticipantInfo } from 'livekit-client/dist/src/proto/livekit_models_pb';
import { GridLayout } from './components';
import { ParticipantTile } from './components/Participant/ParticipantTitle';

interface RoomPageProps {
  url: string;
  token: string;
  layout: string;
}

export default function RoomPage({ url, token, layout }: RoomPageProps) {
  const [error, setError] = useState<Error>();
  if (!url || !token) {
    return <div className="error">missing required params url and token</div>;
  }

  return (
    <LiveKitRoom serverUrl={url} token={token} onError={setError}>
      {error ? <div className="error">{error.message}</div> : <CompositeTemplate layout={layout} />}
    </LiveKitRoom>
  );
}

interface CompositeTemplateProps {
  layout: string;
}

function CompositeTemplate({ layout: initialLayout }: CompositeTemplateProps) {
  interface AdminVisibility {
    screenShare: {
      showAdmin: boolean;
      identity: string;
      source: string;
    };
    cameraSource: {
      showAdmin: boolean;
      identity: string;
      source: string;
    };
  }
  interface ParticipantVisible {
    sid: string;
    source: string;
  }
  const room = useRoomContext();
  const [layout, setLayout] = useState(initialLayout);
  const [hasScreenShare, setHasScreenShare] = useState(false);
  const [selectedCard, setSelectedCard] = useState('grid');
  const [AdminObjectInMetadata, setAdminObjectInMetadata] = useState<AdminVisibility>();
  const [showAdmin, setShowAdmin] = useState(true);
  const [ParticipantVisible, setParticipantVisible] = useState<ParticipantVisible[]>([]);

  const AdminEmail = AdminObjectInMetadata?.cameraSource?.identity;

  const screenshareTracks = useTracks([Track.Source.ScreenShare], {
    onlySubscribed: true,
  });

  useEffect(() => {
    if (room) {
      EgressHelper.setRoom(room);

      // Egress layout can change on the fly, we can react to the new layout
      // here.
      EgressHelper.onLayoutChanged((newLayout) => {
        setLayout(newLayout);
      });

      // start recording when there's already a track published
      let hasTrack = false;
      for (const p of Array.from(room.participants.values())) {
        if (p.tracks.size > 0) {
          hasTrack = true;
          break;
        }
      }

      if (hasTrack) {
        EgressHelper.startRecording();
      } else {
        room.once(RoomEvent.TrackSubscribed, () => EgressHelper.startRecording());
      }
    }
  }, [room]);
  useEffect(() => {
    if (room.metadata) {
      const remoteMetaData = JSON.parse(room.metadata);

      setSelectedCard(remoteMetaData.layout?.name ?? '');
      setAdminObjectInMetadata(remoteMetaData?.adminVisibility ?? {});
      setShowAdmin(remoteMetaData?.adminVisibility?.cameraSource.showAdmin ?? true);
      setParticipantVisible(remoteMetaData.ParticipantVisibilitiy ?? []);
    }
  }, [room.metadata]);
  useEffect(() => {
    if (screenshareTracks.length > 0 && screenshareTracks[0].publication) {
      setHasScreenShare(true);
    } else {
      setHasScreenShare(false);
    }
  }, [screenshareTracks]);

  const allTracks = useTracks(
    [
      { source: Track.Source.Camera, withPlaceholder: true },
      { source: Track.Source.ScreenShare, withPlaceholder: false },
    ],
    {
      onlySubscribed: true,
    },
  );
  let tracks = allTracks?.filter(track => track.participant.name || track.participant.identity === "Media") as TrackReference[];


  const carouselTracks = tracks.filter((track) => {
    if (
      track.participant.identity === AdminEmail &&
      showAdmin === false &&
      track.source === AdminObjectInMetadata?.cameraSource?.source
    ) {
      return false;
    } else if (
      track.participant.identity === AdminEmail &&
      AdminObjectInMetadata?.screenShare?.showAdmin === false &&
      track?.source === AdminObjectInMetadata?.screenShare?.source
    ) {
      return false;
    } else {
      return tracks;
    }
  });

  const filteredTracks = carouselTracks.filter((track) => {
    const participantMetadata = JSON.parse(track.participant.metadata || '{}');

    if (participantMetadata.role === 'admin') {
      return true;
    }

    return (
      track.participant.identity !== room.localParticipant.identity &&
      track.participant.permissions?.canPublish === true &&
      ParticipantVisible?.some(
        (participant) =>
          participant.sid === track.participant.sid && participant.source === track.source,
      )
    );
  });

  let interfaceStyle = 'dark';
  if (layout.endsWith('-light')) {
    interfaceStyle = 'light';
  }

  let containerClass = 'roomContainer';
  if (interfaceStyle) {
    containerClass += ` ${interfaceStyle}`;
  }

  // determine layout to use
  let main: ReactElement = <></>;
  let effectiveLayout = layout;
  if (hasScreenShare && selectedCard === 'grid') {
    effectiveLayout = layout.replace('grid', 'speaker');
  }
  if (room.state !== ConnectionState.Disconnected) {
    if (selectedCard === 'speaker') {
      main = <SpeakerLayout tracks={filteredTracks} />;
    } else if (selectedCard === 'single-speaker') {
      main = <SingleSpeakerLayout tracks={filteredTracks} />;
    } else {
      main = (
        <GridLayout tracks={filteredTracks}>
          <ParticipantTile />
        </GridLayout>
      );
    }
  }

  return (
    <div className={containerClass}>
      {main}
      <RoomAudioRenderer />
    </div>
  );
}
