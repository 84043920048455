/**
 * Copyright 2023 LiveKit, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {  TrackReference, useRoomContext, useVisualStableUpdate, VideoTrack } from '@livekit/components-react';
import { LayoutProps } from './common';
import { useEffect, useState } from 'react';
import { FocusLayout } from './components/layout/FocusLayout';

const SingleSpeakerLayout = ({ tracks: references }: LayoutProps) => {
  const room = useRoomContext()

  const [layoutName, setLayoutName] = useState("");
	const [layoutSource, setlayoutSource] = useState("");
	const [layoutFocusName, setlayoutFocusName] = useState("");

useEffect(() => {
		if (room.metadata) {
			const remoteMetaData = JSON.parse(room.metadata);

			setLayoutName(remoteMetaData.layout?.name ?? "");
			setlayoutSource(remoteMetaData.layout?.focusedSource ?? "");
			setlayoutFocusName(remoteMetaData.layout?.focusedName ?? "");


		}
	}, [room.metadata]);
  const sortedReferences = useVisualStableUpdate(references, 1);
  const mainTrack = sortedReferences.find(track => {
    const trackRef = track as TrackReference;
    return (
      trackRef.participant.identity === layoutFocusName &&
      trackRef.source === layoutSource
    );
  });
  const TrackPresenter = sortedReferences?.find((track) =>
	track?.participant?.identity === mainTrack?.participant?.identity &&
	 track?.source =="camera" 
	
  );
  if (!mainTrack) {
    return null;
  }
  return    <FocusLayout className='single-layout' track={mainTrack as TrackReference}  Presenter={TrackPresenter} />

};

export default SingleSpeakerLayout;
