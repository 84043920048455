import * as React from 'react';
import type { LocalTrackPublication, Participant, TrackPublication } from 'livekit-client';
import { Track } from 'livekit-client';
import type { ParticipantClickEvent, TrackReferenceOrPlaceholder } from '@livekit/components-core';
import { isTrackReference, isTrackReferencePinned, setupParticipantTile, setupTrackMutedIndicator } from '@livekit/components-core';
import { AudioTrack, ConnectionQualityIndicator, FocusToggle, LockLockedIcon, ParticipantContext, ParticipantName, ParticipantPlaceholder, ScreenShareIcon, TrackMutedIndicator, TrackRefContext, useEnsureParticipant, useFacingMode, useFeatureContext, useIsEncrypted, useIsMuted, useIsSpeaking, useMaybeLayoutContext, useMaybeParticipantContext, useMaybeTrackRefContext, useRoomContext } from '@livekit/components-react';
import { mergeProps } from '../utils';
import { useObservableState } from '../hooks/internal';
import SvgParticipantPlaceholder from '@livekit/components-react/dist/assets/images/ParticipantPlaceholder';
import SvgParticipantNamePlaceholder from '../../assets/icons/SvgParticipantNamePlaceholder';
import { VideoTrack } from '../layout/VideoTrack';

/**
 * The `ParticipantContextIfNeeded` component only creates a `ParticipantContext`
 * if there is no `ParticipantContext` already.
 * @example
 * ```tsx
 * <ParticipantContextIfNeeded participant={trackReference.participant}>
 *  ...
 * </ParticipantContextIfNeeded>
 * ```
 * @public
 */
export interface UseParticipantTileProps<T extends HTMLElement> extends React.HTMLAttributes<T> {
    /** The track reference to display. */
    trackRef?: TrackReferenceOrPlaceholder;
    disableSpeakingIndicator?: boolean;
    /** @deprecated This parameter will be removed in a future version use `trackRef` instead. */
    publication?: TrackPublication;
    onParticipantClick?: (event: ParticipantClickEvent) => void;
    htmlProps: React.HTMLAttributes<T>;
    /** @deprecated This parameter will be removed in a future version use `trackRef` instead. */
    source?: Track.Source;
    /** @deprecated This parameter will be removed in a future version use `trackRef` instead. */
    participant?: Participant;
    publicationMenu?: TrackPublication;


  }
export function useParticipantTile<T extends HTMLElement>({
    trackRef,
    participant,
    source,
    publication,
    onParticipantClick,
    publicationMenu,
    disableSpeakingIndicator,
    htmlProps,
  }: UseParticipantTileProps<T>) {
    // TODO: Remove and refactor after deprecation period to use:
    // const trackReference = useEnsureTrackRefContext(trackRef)`.
    const maybeTrackRef = useMaybeTrackRefContext();
    const p = useEnsureParticipant(participant);
    const trackReference = React.useMemo(() => {
      const _source = trackRef?.source ?? maybeTrackRef?.source ?? source;
      if (_source === undefined) {
        throw new Error(
          'Missing track `source`, provided it via `trackRef`, `source` property or via `TrackRefContext`.',
        );
      }
      return {
        participant: trackRef?.participant ?? maybeTrackRef?.participant ?? p,
        publication: trackRef?.publication ?? maybeTrackRef?.publication ?? publication,
        source: _source,
      };
    }, [
      trackRef?.participant,
      trackRef?.source,
      trackRef?.publication,
      maybeTrackRef?.participant,
      maybeTrackRef?.source,
      maybeTrackRef?.publication,
      p,
      source,
      publication,
    ]);
  
    const mergedProps = React.useMemo(() => {
      const { className } = setupParticipantTile();
      return mergeProps(htmlProps, {
        className,
        onClick: (event: React.MouseEvent<T, MouseEvent>) => {
          htmlProps.onClick?.(event);
          if (typeof onParticipantClick === 'function') {
            const track =
              trackReference.publication ??
              trackReference.participant.getTrack(trackReference.source) ;
            onParticipantClick({ participant: trackReference.participant, track });
          }
        },
      });
    }, [
      htmlProps,
      onParticipantClick,
      trackReference.publication,
      trackReference.source,
      trackReference.participant,
    ]);
    const isVideoMuted = useIsMuted(Track.Source.Camera, { participant: trackReference.participant });
    const isAudioMuted = useIsMuted(Track.Source.Microphone, {
      participant: trackReference.participant,
    });
    const isSpeaking = useIsSpeaking(trackReference.participant);
    const facingMode = useFacingMode(trackReference);
    return {
      elementProps: {
        'data-lk-audio-muted': isAudioMuted,
        'data-lk-video-muted': isVideoMuted,
        'data-lk-speaking': disableSpeakingIndicator === true ? false : isSpeaking,
        'data-lk-local-participant': trackReference.participant.isLocal,
        'data-lk-source': trackReference.source,
        'data-lk-facing-mode': facingMode,
        ...mergedProps,
      } as React.HTMLAttributes<T>,
    };
  }
export function ParticipantContextIfNeeded(
  props: React.PropsWithChildren<{
    participant?: Participant;
  }>,
) {
  const hasContext = !!useMaybeParticipantContext();
  return props.participant && !hasContext ? (
    <ParticipantContext.Provider value={props.participant}>
      {props.children}
    </ParticipantContext.Provider>
  ) : (
    <>{props.children}</>
  );
}

/**
 * Only create a `TrackRefContext` if there is no `TrackRefContext` already.
 */
function TrackRefContextIfNeeded(
  props: React.PropsWithChildren<{
    trackRef?: TrackReferenceOrPlaceholder;
  }>,
) {
  const hasContext = !!useMaybeTrackRefContext();
  return props.trackRef && !hasContext ? (
    <TrackRefContext.Provider value={props.trackRef}>{props.children}</TrackRefContext.Provider>
  ) : (
    <>{props.children}</>
  );
}

/** @public */
export interface ParticipantTileProps extends React.HTMLAttributes<HTMLDivElement> {
  /** The track reference to display. */
  trackRef?: TrackReferenceOrPlaceholder;
  disableSpeakingIndicator?: boolean;
  /** @deprecated This parameter will be removed in a future version use `trackRef` instead. */
  participant?: Participant;
  /** @deprecated This parameter will be removed in a future version use `trackRef` instead. */
  source?: Track.Source;
  /** @deprecated This parameter will be removed in a future version use `trackRef` instead. */
  publication?: TrackPublication;
  publicationMenu?:TrackPublication;
  SourceOfPresenter?: Track.Source;

  onParticipantClick?: (event: ParticipantClickEvent) => void;
}

/**
 * The `ParticipantTile` component is the base utility wrapper for displaying a visual representation of a participant.
 * This component can be used as a child of the `TrackLoop` component or by passing a track reference as property.
 *
 * @example Using the `ParticipantTile` component with a track reference:
 * ```tsx
 * <ParticipantTile trackRef={trackRef} />
 * ```
 * @example Using the `ParticipantTile` component as a child of the `TrackLoop` component:
 * ```tsx
 * <TrackLoop>
 *  <ParticipantTile />
 * </TrackLoop>
 * ```
 * @public
 */
export function ParticipantTile({
  trackRef,
  participant,
  children,
  source = Track.Source.Camera,
  onParticipantClick,
  publication,
  disableSpeakingIndicator,
  SourceOfPresenter = Track.Source.Camera,
	publicationMenu ,
  ...htmlProps
}: ParticipantTileProps) {
  // TODO: remove deprecated props and refactor in a future version.
  const maybeTrackRef = useMaybeTrackRefContext();
  const p = useEnsureParticipant(participant);
  const [layoutFocusName, setlayoutFocusName] = React.useState("");
	const [layoutSource, setlayoutSource] = React.useState("");
	const [IsPresenter, setIsPresenter] = React.useState(false);
	const [NamePresenter, setNamePresenter] = React.useState("");
	const [SourcePresenter, setSourcePresenter] = React.useState("");
    const [layoutName, setLayoutName] = React.useState("");
    const audioTracksArray = [...(p?.videoTracks?.values() as any ?? [])];


  const trackReference: TrackReferenceOrPlaceholder = React.useMemo(() => {
    return {
      participant: trackRef?.participant ?? maybeTrackRef?.participant ?? p,
      source: trackRef?.source ?? maybeTrackRef?.source ?? source,
      publication: trackRef?.publication ?? maybeTrackRef?.publication ?? publication,
    };
  }, [maybeTrackRef, p, publication, source, trackRef]);

  const { elementProps } = useParticipantTile<HTMLDivElement>({
    participant: trackReference.participant,
    htmlProps,
    source: trackReference.source,
    publication: trackReference.publication,
    disableSpeakingIndicator,
    onParticipantClick,
  });
  const isEncrypted = useIsEncrypted(p);
  const layoutContext = useMaybeLayoutContext();
  const room = useRoomContext()

  const autoManageSubscription = useFeatureContext()?.autoSubscription;
  React.useEffect(() => {
    if (room.metadata) {
        const remoteMetaData = JSON.parse(room.metadata);

        setlayoutFocusName(remoteMetaData?.layout?.focusedName);
        setlayoutSource(remoteMetaData.layout?.focusedSource ?? "");
        setIsPresenter(remoteMetaData.ScreenSharePresenter?.IsPresent ?? false);
        setNamePresenter(remoteMetaData.ScreenSharePresenter?.name ?? "");
        setSourcePresenter(remoteMetaData.ScreenSharePresenter?.source ?? "")
        setLayoutName(remoteMetaData?.layout?.name ?? "")


    }
}, [room.metadata]);
const shouldPresentInSceenshare = SourceOfPresenter  && IsPresenter
&& p.permissions?.canPublish 
&& source === "screen_share" 
&& (layoutName === "speaker" || layoutName === "single-speaker") 
&& p.identity === layoutFocusName &&  p.identity=== NamePresenter
&& layoutSource === "screen_share" &&SourcePresenter ==="camera" 


  const handleSubscribe = React.useCallback(
    (subscribed: boolean) => {
      if (
        trackReference.source &&
        !subscribed &&
        layoutContext &&
        layoutContext.pin.dispatch &&
        isTrackReferencePinned(trackReference, layoutContext.pin.state)
      ) {
        layoutContext.pin.dispatch({ msg: 'clear_pin' });
      }
    },
    [trackReference, layoutContext],
  );
  const participantInfosPresenter = {
    participant:p,
    source : Track.Source.Camera,
    publication:publicationMenu as TrackPublication

}
const getObjectBySource = (array: any, source: string) => {
  return array?.find((obj: LocalTrackPublication) => obj.source === source);
};

const trackRefVideo: TrackReferenceOrPlaceholder = {
  participant: p as Participant,
  source: getObjectBySource(audioTracksArray, "camera")?.source,
  
};
const videoMutedObserver = React.useMemo(
  () => setupTrackMutedIndicator(trackRefVideo),
  [p,getObjectBySource(audioTracksArray, "camera")]
);

const isCameraMuted = useObservableState(
  videoMutedObserver.mediaMutedObserver,
  !!p?.videoTracks.values().next().value?.metadataMuted
);

  return (
    <div style={{ position: 'relative' }} {...elementProps}>
      <TrackRefContextIfNeeded trackRef={trackReference}>
        <ParticipantContextIfNeeded participant={trackReference.participant}>
          {children ?? (
            <>
              {isTrackReference(trackReference) &&
              (trackReference.publication?.kind === 'video' ||
                trackReference.source === Track.Source.Camera ||
                trackReference.source === Track.Source.ScreenShare) ? (
                <VideoTrack
                  trackRef={trackReference}
                  onSubscriptionStatusChanged={handleSubscribe}
                  manageSubscription={autoManageSubscription}
                />
              ) : (
                isTrackReference(trackReference) && (
                  <AudioTrack
                    trackRef={trackReference}
                    onSubscriptionStatusChanged={handleSubscribe}
                  />
                )
              )}
              {shouldPresentInSceenshare ? (
					  <div className="PresenterMode">
{!isCameraMuted ?  (
	          <>   <VideoTrack 
					trackRef={participantInfosPresenter}

				/>
				<div className="lk-participant-metadata">
			<div className="lk-participant-metadata-item">
					<TrackMutedIndicator
										  source={Track.Source.Microphone}
										  show={"muted"}
										></TrackMutedIndicator>
										<ParticipantName /></div>  		 
			  </div>	
</>
				):

<div className="PlaceholderPresenter">
<div
														style={{
															display: "flex",
															justifyContent: "center",
															alignItems: "center",
															width: "100%",
															height: "100%",
														}}
													>
        <SvgParticipantNamePlaceholder style={{ width: '100%', height: '100%'
}} />
        <div  className="participantNamePlaceholder">
          {p?.identity.charAt(0).toUpperCase()}
        </div>
      </div>				
      
      <div className="lk-participant-metadata">
			<div className="lk-participant-metadata-item">
					<TrackMutedIndicator
										  source={Track.Source.Microphone}
										  show={"muted"}
										></TrackMutedIndicator>
										<ParticipantName /></div>  		 
			  </div>	
			  </div>	
}



                      </div>




              ):null}
              <div className="lk-participant-placeholder">
                <ParticipantPlaceholder />
              </div>
              {p.identity !=="Media" ? 	

              <div className="lk-participant-metadata">
                <div className="lk-participant-metadata-item">
                  {trackReference.source === Track.Source.Camera ? (
                    <>
                      {isEncrypted && <LockLockedIcon style={{ marginRight: '0.25rem' }} />}
                      <TrackMutedIndicator
                        source={Track.Source.Microphone}
                        show={'muted'}
                      ></TrackMutedIndicator>
                      <ParticipantName />
                    </>
                  ) : (
                    <>
                      <ScreenShareIcon style={{ marginRight: '0.25rem' }} />
                      <ParticipantName>&apos;s screen</ParticipantName>
                    </>
                  )}
                </div>
                <ConnectionQualityIndicator className="lk-participant-metadata-item" />
              </div>
              :null}
            </>
          )}
          <FocusToggle trackRef={trackReference} />
        </ParticipantContextIfNeeded>
      </TrackRefContextIfNeeded>
    </div>
  );
}
