import * as React from "react";
import { SVGProps } from "react";
const SvgParticipantNamePlaceholder = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={320}
		height={320}
		viewBox="0 -50 320 320"
		preserveAspectRatio="xMidYMid meet"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M160 180C204.182 180 240 144.183 240 100C240 55.8172 204.182 20 160 20C115.817 20 79.9997 55.8172 79.9997 100C79.9997 144.183 115.817 180 160 180Z"
			fill="white"
			fillOpacity={0.25}
		/>
	</svg>
);
export default SvgParticipantNamePlaceholder;
