/**
 * Copyright 2023 LiveKit, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { TrackReference } from '@livekit/components-core';
import {
  CarouselView,
  VideoTrack,
  useRoomContext,
  useVisualStableUpdate,
} from '@livekit/components-react';
import { LayoutProps } from './common';
import { useEffect, useState } from 'react';
import { FocusLayout } from './components/layout/FocusLayout';
import { ParticipantTile } from './components/Participant/ParticipantTitle';

const SpeakerLayout = ({ tracks: references }: LayoutProps) => {
  const room = useRoomContext()
  const sortedTracks = useVisualStableUpdate(references, 1);
  const [layoutName, setLayoutName] = useState("");
	const [layoutSource, setlayoutSource] = useState("");
	const [layoutFocusName, setlayoutFocusName] = useState("");
  const [IsPresenter, setIsPresenter] = useState(false);
	const [NamePresenter, setNamePresenter] = useState("");
	const [SourcePresenter, setSourcePresenter] = useState("");
useEffect(() => {
		if (room.metadata) {
			const remoteMetaData = JSON.parse(room.metadata);

			setLayoutName(remoteMetaData.layout?.name ?? "");
			setlayoutSource(remoteMetaData.layout?.focusedSource ?? "");
			setlayoutFocusName(remoteMetaData.layout?.focusedName ?? "");
      setIsPresenter(remoteMetaData.ScreenSharePresenter?.IsPresent ?? false);
			setNamePresenter(remoteMetaData.ScreenSharePresenter?.name ?? "");
			setSourcePresenter(remoteMetaData.ScreenSharePresenter?.source ?? "");

		}
	}, [room.metadata]);
  const mainTrack = sortedTracks.find(track => {
    const trackRef = track as TrackReference;
    return (
      trackRef.participant.identity === layoutFocusName &&
      trackRef.source === layoutSource
    );
  });
  const remainingTracks = useVisualStableUpdate(sortedTracks, 3);
  const filteredRemainingTracks = remainingTracks.filter(track => track !== mainTrack).sort();
  const FiltredTrackInSpeaker = filteredRemainingTracks?.filter((track) => {

		if (
		  track.source === "camera" &&
		  layoutSource === "screen_share" && 
		  track.participant.identity === layoutFocusName 
		  && layoutFocusName === NamePresenter
		  && layoutSource !==SourcePresenter
		  && IsPresenter===true
		) {
		  return false; 
		}
		
		return true; 
	  });
  const TrackPresenter = remainingTracks?.find((track) =>
	track?.participant?.identity === mainTrack?.participant?.identity &&
	 track?.source =="camera" 
	
  );
  if (!mainTrack) {
    return <>
        <div className="lk-focus-layout">

     <CarouselView tracks={FiltredTrackInSpeaker}>
        <ParticipantTile />
      </CarouselView>
      </div>
      </>;
  } else if (remainingTracks.length === 0) {
    const trackRef = mainTrack as TrackReference;
    return <VideoTrack {...trackRef} />;
  }

  return (
    FiltredTrackInSpeaker.length> 0 ? (
      <div className="lk-focus-layout">
      <CarouselView tracks={FiltredTrackInSpeaker}>
        <ParticipantTile />
      </CarouselView>
      <FocusLayout track={mainTrack as TrackReference} Presenter={TrackPresenter}  />
    </div>


    ):  <FocusLayout  className='single-layout' track={mainTrack as TrackReference} Presenter={TrackPresenter}  />

   
  );
};

export default SpeakerLayout;
